<template>
  <div
    class="electro-discharge-machine px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/ESD-machine-min.jpg"
          width="1555"
          height="1037"
          alt=""
          title="SMS ABL ESD Machine"
          class="img-responsive wp-image-12201"
        />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="SMS ABL Electrostatic Discharge Machine"
          size="large"
        />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) manufactures the ABL
            Electrostatic Discharge (ESD) Machine for clients who require
            in-house testing capability to determine energetic material response
            to an ESD event.&nbsp; The ABL ESD machine is one of the industries
            core sensitivity test machines for producing industry standard
            levels of energetic material sensitivity data.
          </p>
          <p>
            The ABL ESD Test Apparatus delivers an electric shock or spark
            through a small sample of test material.&nbsp; Reaction of the
            material is determined by operator observation, detection of
            decomposition by a gas analyzer, or utilizing the SMS developed
            <router-link
              class="link"
              title="GoDetect-ESD"
              to="/test-equipment/godetect"
              >GoDetect™</router-link
            >
            high speed video detection system.&nbsp; The amount of energy
            required to cause a material to react is used to evaluate the
            sensitivity of the material to an ESD event.&nbsp; The sensitivity
            data can also be used to determine critical safety parameters
            related to manufacturing and handling of the materials being tested.
          </p>
          <p>
            Current practices in industry are concentrated on determining if a
            material can be safely handled for experimental, developmental, and
            production purposes.&nbsp; Typically, scale-up procedures for
            materials that are potentially sensitive to electrical shock require
            some form of testing to evaluate the energy required for
            initiation.&nbsp; The measured sensitivities are compared with
            in-process energy potential to determine if safe limits could be
            exceeded during processing or handling.&nbsp; The ABL ESD machine is
            capable of yielding results in engineering units for direct
            correlation to in-process energy.
          </p>
          <p>
            The ESD test apparatus is capable of performing both the
            “approaching needle” method and the “fixed gap” method of ESD
            tests.&nbsp; The most commonly used approaching needle method is
            intended to simulate an event of two objects approaching one
            another, one with a positive and one with a negative charge, such
            that a discharge occurs at a finite separation distance.&nbsp; To
            accomplish this, the discharge needle is moved rapidly toward the
            test sample by an air-operated solenoid.&nbsp; As the gap between
            the needle and ground closes, the electrical energy is discharged
            through the sample.
          </p>
        </Paragraph>
        <a
          class="heading-link"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2014-SMS-ESD-Machine-Standard-Features_B.pdf?alt=media&token=c3d1ad65-44fd-4f3b-a29c-659b7c0e3e31"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              SMS ABL ESD Machine Technical Specifications
            </h2>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "SMS ABL Electrostatic Discharge",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The SMS ABL Electrostatic Discharge (ESD) assesses the sensitivity of a material to an ESD type event. The apparatus yields result in engineering units for direct correlation with in-process energy."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `<div style="position: relative;width: 100%;padding-bottom: 56.25%;"><iframe src="https://player.vimeo.com/video/489986907" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>`,
          title: "ESD Testing of Smokeless Powder on High-Speed Video"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.electro-discharge-machine {
  .link {
    color: #aa222c;
  }
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
